<template>
    <div>
        <img
            v-if="item.status == 'Sold'"
            class="vin-sold ma-2"
            src="/img/sold.png"
            alt="Sold">
        <span v-if="item.status == 'For Sale'">For Sale</span>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.vin-sold {
    width: 45px;
    height: auto;
}
</style>